
import { Options, Vue } from 'vue-class-component';
import { UserMatchClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({})
export default class UserMatchesList extends Vue {

    items: OM.MatchSummaryVM[] = [];
    userIdentifier: string = "";

    created(){
        this.userIdentifier = this.$route.params.userIdentifier as string;
        UserMatchClient.getAllMatchesByUserIdentifier(this.userIdentifier)
        .then(x => {
            this.items = x;
        })
    }

    get getUserName() {
        if(this.items.length == 0)
            return "";

        var first = this.items[0];
        if(first.applicantUser.identifier == this.userIdentifier)
            return first.applicantUser.text;
        else
            return first.otherUser.text;
    }

}
